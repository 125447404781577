<template>
  <v-container>
    <v-card class="mt-2">
      <v-alert type="info" text>
        Neues Benutzerkonto kurzfristig von Hand erzeugen:
        <ol>
          <li>AccountUpdateJob</li>
          <li>GroupUpdateJob</li>
          <li>SubstitutionUpdateJob</li>
          <li>IdWorksSyncJob</li>
          <li>AdSyncJob</li>
        </ol>
      </v-alert>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        hide-default-footer
        disable-pagination
        sort-by="name"
        :search="search"
      >
        <template v-slot:item.lastRun="{ item }">
          <DateValue :value="item.lastRunDate" />, {{ item.lastRunTime }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
        </template>
        <template v-slot:item.runNow="{ item }">
          <v-simple-checkbox v-model="item.runNow" disabled></v-simple-checkbox>
        </template>
        <template v-slot:item.interval="{ item }">
          <v-simple-checkbox
            v-model="item.interval"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.error="{ item }">
          <v-icon v-if="item.error" color="error">mdi-close</v-icon>
          <v-icon v-else color="success">mdi-check</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="showDetails(item.id)" icon
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
import DateValue from "common/components/DateValue.vue";

export default {
  name: "JobsList",
  components: { DateValue },
  props: ["search"],
  data: () => ({
    loading: false,
    items: [],
    headers: [
      { text: "name", value: "name" },
      { text: "status", value: "error" },
      { text: "lastRun", value: "lastRun" },
      { text: "time", value: "time" },
      { text: "active", value: "active" },
      { text: "runNow", value: "runNow" },
      { text: "interval", value: "interval" },
      { text: "intervalSeconds", value: "intervalSeconds" },
      { text: "", value: "actions" },
    ],
  }),
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "job/job" });
      this.loading = false;
    },
    showDetails(id) {
      this.$router.push({
        name: "JobDetails",
        params: { id: id },
      });
    },
  },
  async created() {
    this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "JobsList") {
      this.getData();
    }
    next();
  },
};
</script>
